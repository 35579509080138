import React from 'react'
import Layout from '../components/layout'
import { ContactForm } from '../components/forms'
import Seo from '../components/seo'
import InfoSection from '../components/ui/InfoSection'

import '../assets/Scss/templates/contact.scss'

const ContactPage = () => {
  return (
    <Layout bodyClass="contact-page">
      <Seo title="Contact Us" />
      <header className="page-header">
        <h1 className="-title">Contact us!</h1>
        <h3 className="-desc">
          Our team is ready to answer your questions.
        </h3>
      </header>
      <div className="container contact-us">
        <ContactForm />
        <InfoSection />
      </div>
    </Layout>
  )
}

export default ContactPage
