import React from 'react'

import './info.scss'

const InfoSection = () => {
  return (
    <section className="contact-info">
      <h4 className="section-title">AWRA is a non-government organization, also known as Stichting registered at the Dutch Chamber of Commerce.</h4>
      <div className="section-row">
        <p className="-title">RSIN:</p>
        <p className="-desc">862265538</p>
      </div>
      <div className="section-row">
        <p className="-title">African Women Rights Advocates (AWRA) Mailing Address:</p>
        <p className="-desc">Unit 0 12 Hillegomstraat 10-1 Amsterdam, North Holland 1058LS Netherlands</p>
      </div>
      <div className="section-row">
        <p className="-title">Contact Email:</p>
        <p className="-desc">info@awra-group.org</p>
      </div>
      <div className="section-row">
        <p className="-title">For Press Inquiries:</p>
        <p className="-desc">media@awra-group.org</p>
      </div>
    </section>
  )
}

export default InfoSection
